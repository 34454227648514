const Settings = ({ width, height, fill, ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" fill="none" {...props}>
<g fill="none"><circle cx="9" cy="16" r="2" fill="currentColor" opacity="0.16"/><circle cx="15" cy="8" r="2" fill="currentColor" opacity="0.16"/><path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 8h9m4 0h3m-9 8h9M4 16h3"/><circle cx="9" cy="16" r="2" stroke="currentColor" stroke-width="2"/><circle cx="15" cy="8" r="2" stroke="currentColor" stroke-width="2"/></g>
  </svg>
)

Settings.defaultProps = {
  width: 18,
  height: 19,
  fill: '#475569',
}

export { Settings }
