import { useState } from 'react'
import Checkbox from '../newCheckbox'

export default function CheckboxGroup({ items, onChange: onGroupChange, ...props }) {
  const [checkboxes, setCheckboxes] = useState(items)

  const handleCheckboxChange = (index, item) => (e) => {
    const newCheckboxes = [...checkboxes]
    newCheckboxes[index].checked = e.target.checked
    setCheckboxes(newCheckboxes)

    if (item.onClick) item.onClick()
    if (onGroupChange) {
      onGroupChange(newCheckboxes)
    }
  }

  return (
    <div {...props}>
      {checkboxes.map((item, index) => (
        <Checkbox key={item.value} className="" item={item} onChange={handleCheckboxChange(index, item)} />
      ))}
    </div>
  )
}
