import { Switch } from 'components/lib'
import Tippy from '@tippyjs/react'
import { roundArrow } from 'tippy.js'
import 'tippy.js/dist/tippy.css' // Core styles
import 'tippy.js/dist/svg-arrow.css' // SVG arrow styles
import 'tippy.js/animations/shift-away.css'

export function ChatModeSelector({ mode, setMode }) {
  return (
    <div
      id="modeSelector"
      //  className="w-30 absolute hidden lg:inline select-none ml-20 z-20 mt-5"
    >
      <Tippy
        content="The AI does more research, but ability to answer follow up questions is hindered (recommended)"
        animation="shift-away"
        arrow={roundArrow}
        inertia={true}
      >
        <span className="font-medium select-none text-[0.8rem]">Deep Dive</span>
      </Tippy>
      <Switch
        default={mode === 'Turbo' ? true : false}
        name="Research"
        required={true}
        onChange={(_, checked) => {
          if (checked) {
            setMode('Turbo')
          } else {
            setMode('Default')
          }
        }}
      />
    </div>
  )
}
