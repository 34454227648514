import dayjs from 'dayjs'
import { OS_TYPE } from './constants'

// Function to format the date
export const categorizeDate = (timeString) => {
  const date = dayjs(timeString)
  const today = dayjs().startOf('day')

  const diffInDays = today.diff(date.startOf('day'), 'day')

  if (diffInDays === 0) {
    return 'Today'
  } else if (diffInDays === 1) {
    return 'Yesterday'
  } else if (diffInDays <= 7) {
    return `Previous 7 Days`
  } else if (diffInDays <= 30) {
    return `Previous 30 Days`
  } else {
    return date.format('MMMM')
  }
}

export const formatDate = (date, format = 'DD-MM-YYYY') => {
  let localDateString = new Date(date)
  return dayjs(localDateString).format(format)
}
export function getSerializedDns(data) {
  if (!data) return {}
  const result = {}

  function recurse(obj, parentCategory = '') {
    for (const key in obj) {
      if (typeof obj[key] === 'object' && obj[key] !== null) {
        if ('namespace' in obj[key]) {
          let resultKey = key
          // LV This is a very dirty hack to get the worker lambdas to work
          // without needing to do a coupled release. Ideally the whole way
          // we send namespaces should be redone. This fix is good enough
          // until we (most likely me) change the way we send namespaces.
          if (key === 'Income Tax Act 2007') resultKey = 'Income Tax Act'

          result[resultKey] = {
            namespace: obj[key].namespace,
            checked: obj[key].checked,
          }
        } else {
          recurse(obj[key], key)
        }
      } else if (parentCategory && key === 'namespace') {
        result[parentCategory] = {
          namespace: obj[key],
          checked: obj.checked,
        }
      }
    }
  }

  recurse(data)
  return result
}

export const highlightText = (content, highlight) => {
  if (!highlight) return content
  const regex = new RegExp(`(${highlight})`, 'gi')
  return content.split(regex).map((part, index) =>
    regex.test(part) ? (
      <span key={index} className="font-medium">
        {part}
      </span>
    ) : (
      part
    )
  )
}

export const truncateContent = (content, maxLength) => {
  return content.length > maxLength ? `${content.substring(0, maxLength)}...` : content
}

export function cn(...inputs) {
  const classes = inputs.flatMap((input) => {
    if (typeof input === 'string') {
      return input.split(' ')
    }
    if (Array.isArray(input)) {
      return input.flatMap((item) => cn(item))
    }
    if (typeof input === 'object' && input !== null) {
      return Object.entries(input)
        .filter(([, value]) => Boolean(value))
        .map(([key]) => key)
    }
    return []
  })

  const uniqueClasses = new Set(classes)
  return Array.from(uniqueClasses).join(' ')
}

export const getOS = () => {
  const userAgent = window.navigator.userAgent.toLowerCase()

  for (const [os, pattern] of Object.entries(OS_TYPE)) {
    if (pattern.test(userAgent)) {
      return os
    }
  }

  return 'unknown'
}

/**
 * Custom function to sanitize HTML by removing potentially dangerous elements and attributes.
 * @param {string} html - The HTML string to sanitize.
 * @returns {string} - The sanitized HTML string.
 */
export function sanitizeHTML(html) {
  const tempDiv = document.createElement('div')
  tempDiv.innerHTML = html

  function sanitizeNode(node) {
    const forbiddenTags = ['script', 'iframe', 'object', 'embed', 'noscript']
    const forbiddenAttributes = ['onload', 'onerror', 'onclick', 'onsubmit', 'onmouseover', 'onfocus', 'oninput']

    if (forbiddenTags.includes(node.nodeName.toLowerCase())) {
      node.remove()
      return
    }

    if (node.attributes) {
      for (let attr of Array.from(node.attributes)) {
        if (forbiddenAttributes.includes(attr.name.toLowerCase())) {
          node.removeAttribute(attr.name)
        } else if (['href', 'src'].includes(attr.name.toLowerCase())) {
          const attrValue = node.getAttribute(attr.name).toLowerCase()
          if (attrValue.startsWith('javascript:')) {
            node.removeAttribute(attr.name)
          } else if (attrValue.startsWith('data:') && !attrValue.startsWith('data:image/')) {
            node.removeAttribute(attr.name)
          }
        }
      }
    }

    if (node.childNodes) {
      for (let child of Array.from(node.childNodes)) {
        sanitizeNode(child)
      }
    }
  }

  if (tempDiv.childNodes) {
    for (let child of Array.from(tempDiv.childNodes)) {
      sanitizeNode(child)
    }
  }

  return tempDiv.innerHTML
}
/**
 * Download file function
 * @param {string} downloadURL - The URL of the file to be downloaded
 * @param {string} fileName - The name of the file to be downloaded
 * @returns {Promise<void>} - A promise that resolves when the file has been downloaded
 */

export const downloadFile = async (downloadURL, fileName) => {
  try {
    const response = await fetch(downloadURL)
    const blob = await response.blob()
    const url = URL.createObjectURL(blob)
    const link = Object.assign(document.createElement('a'), {
      href: url,
      download: fileName,
    })

    link.click()
    URL.revokeObjectURL(url)
  } catch (error) {
    console.error('Error downloading document:', error)
  }
}
/**
 * Cleans nested objects by removing empty values
 * @param {Object} data - The object to be cleaned
 * @returns {Object} - The cleaned object
 */

export function cleanNestedObject(data) {
  const cleanedData = {}

  for (const key in data) {
    const value = data[key]

    if (typeof value === 'object' && value !== null) {
      const cleanedNestedData = cleanNestedObject(value)

      if (Object.keys(cleanedNestedData).length > 0) {
        cleanedData[key] = cleanedNestedData
      }
    } else if (value === true) {
      cleanedData[key] = true
    }
  }

  return cleanedData
}

/**
 * Toggles the values of the second object in the first object
 * @param {Object} firstObj - The object to be modified
 * @param {Object} secondObj - The object to be toggled
 * @returns {Object} - The modified first object
 */

export const toggleObjectValues = (firstObj, secondObj) => {
  const result = JSON.parse(JSON.stringify(firstObj))

  for (const category in secondObj) {
    if (result.hasOwnProperty(category)) {
      for (const key in secondObj[category]) {
        if (result[category].hasOwnProperty(key)) {
          result[category][key] = secondObj[category][key]
        }
      }
    }
  }

  return result
}
