import { Filter } from 'components/filter'
import SearchForm from './searchForm'
// import Pagination from 'components/pagination/pagination'
import useCallAPI from 'components/hooks/callApi'
import { useWindowSize } from 'hooks'
import { Link, useSearchParams } from 'react-router-dom'
import ResultItem from './individualResults'
import { ViewContext } from 'components/lib'
import { useContext, useEffect } from 'react'
import { SearchInputContext } from './searchInputContext'

const SearchResults = () => {
  const viewContext = useContext(ViewContext)
  const [searchParams, setSearchParams] = useSearchParams()
  const { searchInputValue, setSearchInputValue } = useContext(SearchInputContext)
  const fromToolPage = searchParams.get('fromToolPage') === 'true'
  const searchKey = searchParams.get('searchKey') || ''

  const { width: windowWidth } = useWindowSize()

  const [search, results, loading, error] = useCallAPI({
    url: '/api/search',
    method: 'POST',
  })

  // const currentPage = parseInt(searchParams.get('page') || '1', 10)
  // const totalPages = Math.ceil(results?.totalResults / 10 || 1)

  // useEffect(() => {
  //   if (searchParams.get('searchKey')) {
  //     search()
  //   }
  // }, [searchParams])

  function transformObject(inputObj) {
    if (inputObj == null) {
      return {}
    }

    return Object.fromEntries(
      Object.entries(inputObj)
        .map(([category, subCategories]) => [
          category,
          Object.keys(subCategories || {}).filter((key) => subCategories[key]),
        ])
        .filter(([, trueKeys]) => trueKeys.length)
    )
  }

  function getActiveNamespaces(obj1, obj2, path = '') {
    const namespaces = []

    for (const key in obj2) {
      if (obj2.hasOwnProperty(key)) {
        const newPath = path ? `${path}.${key}` : key

        if (typeof obj2[key] === 'object' && obj2[key] !== null) {
          const nestedNamespaces = getActiveNamespaces(obj1[key] || {}, obj2[key], newPath)
          namespaces.push(...nestedNamespaces)
        } else if (obj1[key]) {
          namespaces.push(obj1[key])
        }
      }
    }

    return namespaces
  }

  // const handlePageChange = (page) => {
  //   setSearchParams((prev) => {
  //     prev.set('page', page.toString())
  //     return prev
  //   })
  // }

  const handleSubmit = async () => {
    setSearchParams((prev) => {
      prev.set('searchKey', searchInputValue)
      // prev.set('page', '1')
      return prev
    })

    if (searchInputValue === '') {
      viewContext.notification.show('Please enter a search term', 'error')
      return
    }

    if (searchInputValue !== '') {
      let namespaces = getActiveNamespaces(
        JSON.parse(localStorage.getItem('namespaceMap') || '{}'),
        JSON.parse(searchParams.get('nSpaces') || '{}')['Document Types']
      )

      if (namespaces.length === 0 && !fromToolPage) {
        viewContext.notification.show('You must select a document type before searching', 'error')
        return
      }
      search({
        requestData: {
          query: searchInputValue,
          sortBy: searchParams.get('sortBy') || 'Most Recent',
          filters: {
            practice_area: searchParams.get('pAreaLabel') ? searchParams.get('pAreaLabel') : 'NZ Tax',
            namespaces,
            categories: transformObject(JSON.parse(searchParams.get('filters'))?.category),
          },
          searchMode: searchParams.get('targetMode') === 'true' ? 'strict' : 'smart',
          fromToolPage: fromToolPage,
        },
      })

      if (fromToolPage) {
        setSearchParams((prev) => {
          prev.delete('fromToolPage')
          return prev
        })
      }
    }
  }

  useEffect(() => {
    if (searchKey && fromToolPage === true) {
      handleSubmit(searchKey)
      setSearchInputValue(searchKey)
    }
  }, [searchKey])

  return (
    <div className="flex !pt-4 gap-2 h-full overflow-y-auto justify-between">
      {windowWidth > 1280 ? (
        <div className="logoMargin text-xl my-5 mx-8 font-bold">
          <Link className="text-primary-dark" to="/search">
            <img
              className="text-primary-dark h-14"
              src="https://ominous.nz/storage/Cropped-Seafarer-Logo.svg"
              alt="Law Cyborg Search Logo"
            />
          </Link>
        </div>
      ) : null}
      <div className="left flex-1">
        <div className="results mx-4">
          <div className="w-full">
            <SearchForm handleSubmit={handleSubmit} searchParams={searchParams} className="bg-white" />
          </div>
          <div className="results px-2 my-4 max-w-[800px] mx-auto">
            {loading ? (
              <p className="ml-4">Loading...</p>
            ) : error ? (
              <p className="text-red-500"> An error occurred while fetching results.</p>
            ) : (
              results?.data?.length > 0 && (
                <>
                  <span className="resultSummary text-sm text-[#475569] ml-4">
                    {results?.data?.length > 0
                      ? `Showing: 1 to ${results.data.length} results for "${searchKey}"`
                      : `Showing 0 results for "${searchKey}"`}
                  </span>

                  {results?.data?.map((result, index) => (
                    <div key={index} className="border-b py-2">
                      <ResultItem id={index} {...result} highlight={searchKey} />
                    </div>
                  ))}
                  {/* TODO: pagination should only show up when there are more than 10 results */}
                  {/* <Pagination
                  totalPages={totalPages}
                  currentPage={currentPage}
                  onPageChange={handlePageChange}
                  visiblePages={5}
                  onRowsPerPageChange={() => {}}
                  className="custom-pagination py-4"
                /> */}
                </>
              )
            )}
          </div>
        </div>
      </div>
      <div className="right my-4 ml-8 min-w-64 max-w-72">
        <Filter />
      </div>
    </div>
  )
}

export default SearchResults
