const LicenseIcon = ({ width = 16, height = 14, fill = '#9199A5', ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    stroke="2"
    viewBox="0 0 16 14"
    width={width}
    height={height}
    fill="none"
    {...props}
  >
    <path
      fill={fill}
      stroke="2"
      d="M15.478 0H.522A.522.522 0 0 0 0 .522v11.005c0 .288.234.521.522.521H9.04v.925a.522.522 0 0 0 .811.434l.754-.502.754.502a.523.523 0 0 0 .812-.434v-.925h3.307a.522.522 0 0 0 .522-.521V.521A.522.522 0 0 0 15.478 0Zm-4.873 5.85c.957 0 1.735.779 1.735 1.736 0 .64-.358 1.225-.934 1.528a1.659 1.659 0 0 1-1.602 0 1.727 1.727 0 0 1-.934-1.528c0-.957.778-1.736 1.735-1.736Zm.522 6.148-.232-.155a.52.52 0 0 0-.58 0l-.231.155v-1.685c.018.003.037.002.056.005.154.028.309.046.465.046.157 0 .312-.018.466-.046.018-.003.037-.002.056-.005v1.685Zm3.83-.993H12.17V9.87c.01-.006.017-.013.026-.02.037-.025.07-.054.106-.081.06-.047.12-.094.176-.145.036-.033.069-.067.103-.102a2.747 2.747 0 0 0 .237-.272 2.787 2.787 0 0 0 .294-.482c.02-.044.042-.088.06-.133.028-.067.051-.135.073-.204.015-.044.03-.087.043-.132.02-.077.035-.156.049-.235.007-.039.017-.077.022-.116a2.782 2.782 0 0 0-2.753-3.141c-.04 0-.076.01-.114.011-.02-.002-.037-.011-.057-.011H2.783a.522.522 0 0 0 0 1.043h5.67a2.748 2.748 0 0 0-.592 1.392H2.783a.522.522 0 0 0 0 1.043h5.135l.004.014c.012.045.028.088.042.131.022.07.046.138.074.206.018.045.04.088.06.132a2.764 2.764 0 0 0 .388.6c.044.052.092.102.141.152.035.035.07.072.107.105.054.05.112.095.17.14.037.029.073.06.111.086l.025.02v1.134H1.043V1.043h13.913v9.962Z"
    />
    <path fill={fill} d="M2.783 3.416h10.434a.522.522 0 0 0 0-1.044H2.783a.522.522 0 0 0 0 1.044Z" />
  </svg>
)

export { LicenseIcon }
