import useCallAPI from 'components/hooks/callApi'
import { Animate, Card, Table, TitleRow } from 'components/lib'
import { useEffect } from 'react'
import PurchaseProducts from './purchaseProducts'

export default function Products(props) {
  const [getProductList, productResponse, loading] = useCallAPI({
    url: '/api/assigned-product-list',
  })

  const products = productResponse?.data?.map((product) => ({
    ...product,
    purchased_quantity: {
      current: product.assigned_license,
      total: product.purchased_quantity,
    },
  }))

  useEffect(() => {
    getProductList()
  }, [])

  return (
    <Card className={props.className}>
      <Animate>
        <TitleRow className="mt-5 px-4" title="Products">
          <PurchaseProducts onPurchase={getProductList} />
        </TitleRow>

        <Card className="border rounded">
          <Table
            search={products?.length > 10}
            className="restrict-width"
            loading={loading}
            data={products}
            show={['name', 'assigned_license', 'purchased_quantity']}
          />
        </Card>
      </Animate>
    </Card>
  )
}
