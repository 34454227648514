import { useEffect } from 'react'
import FilterCategory from './filterCategory'
import Radio from './radio.component'
import { Icon } from 'components/lib'

const Filter = ({
  filters,
  onFilterChange,
  onNamespaceChange,
  selectedPracticeArea,
  onSortChange,
  practiceArea = [],
  onPracticeAreaChange,
  onRemovePracticeArea,
}) => {
  const sortByOptions = ['Relevance', 'Most Recent']
  useEffect(() => {
    onPracticeAreaChange({ target: { value: 'tax' } })
  }, [])
  return (
    <div className=" px-2">
      {/* FIXME: uncomment when we have a sortBy option & padding from practice area*/}
      {/* <div className="pt-4 pb-2">
        <h2 className="uppercase font-semibold text-sm text-primary-dark mb-1">Sort By</h2>
        {sortByOptions.map((option) => (
          <Radio
            key={option}
            id={option}
            checked={filters.sortBy === option}
            onChange={() => onSortChange(option)}
            label={option}
            value={option}
            className={'py-2'}
          />
        ))}
      </div> */}
      <div className={`${selectedPracticeArea !== '' ? 'flex items-start justify-between pt-4' : 'pt-4'}`}>
        <h2 className="uppercase font-semibold text-sm text-primary-dark m-1">Practice Area</h2>
        {selectedPracticeArea ? (
          <div className="inline-flex items-center border border-gray-300 rounded-md px-2.5 py-1 text-sm mb-2 bg-[#4755690D] ">
            <span>{selectedPracticeArea}</span>
            {/* FIXME: uncomment when we have other practice area */}
            {/* <button className="text-gray-500 hover:text-gray-700 focus:outline-none" onClick={onRemovePracticeArea}>
              <Icon image="x" size={16} />
            </button> */}
          </div>
        ) : (
          practiceArea?.length > 0 &&
          practiceArea.map((option) => (
            <Radio
              key={option.key}
              id={option.key}
              onChange={onPracticeAreaChange}
              label={option.name}
              value={option.key}
              className={'py-2'}
            />
          ))
        )}
      </div>
      {selectedPracticeArea !== '' && (
        <div className="max-h-[20rem] min-w-[18rem] border p-[12px] mt-5 rounded-lg overflow-y-auto">
          {Object.keys(filters.filters).map((category) => (
            <FilterCategory
              key={category}
              category={category}
              options={filters.filters[category]}
              onFilterChange={(optionPath, isChecked) => onFilterChange(category, optionPath, isChecked)}
            />
          ))}
        </div>
      )}
      {filters?.namespaces && Object.keys(filters.namespaces).length > 0 && (
        <div className="max-h-[20rem] min-w-[18rem] border p-[12px] mt-5 rounded-lg overflow-y-auto">
          {Object.keys(filters.namespaces).map((category) => (
            <FilterCategory
              key={category}
              category={category}
              options={filters.namespaces[category]}
              onFilterChange={(optionPath, isChecked) => onNamespaceChange(category, optionPath, isChecked)}
            />
          ))}
        </div>
      )}
    </div>
  )
}

export default Filter
