import { Button } from 'components/lib'
import { SearchIcon } from 'icons'
import 'tippy.js/dist/tippy.css' // Core styles
import 'tippy.js/dist/svg-arrow.css' // SVG arrow styles
import 'tippy.js/animations/shift-away.css'
import { SearchInputContext } from './searchInputContext'
import { useContext, useRef } from 'react'

const SearchForm = ({ handleSubmit, className, autoFocus, searchParams }) => {
  const { searchInputValue, setSearchInputValue } = useContext(SearchInputContext)
  const inputRef = useRef(null)
  const handleFormSubmit = (e) => {
    e.preventDefault()
    if (handleSubmit) handleSubmit()
  }

  const handleSearchInputChange = (e) => {
    setSearchInputValue(e.target.value)
  }

  const handleInputClear = () => {
    setSearchInputValue('')
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleFormSubmit(e)
    }
  }

  // FIXME: uncomment when we have a target mode
  // const isTargetMode = searchParams?.get('targetMode') === 'true'

  // const toggleTargetMode = () => {
  //   setSearchParams((prev) => {
  //     if (prev.has('targetMode')) {
  //       prev.delete('targetMode')
  //     } else {
  //       prev.set('targetMode', 'true')
  //     }
  //     return prev
  //   })
  // }

  return (
    <form
      onSubmit={handleFormSubmit}
      noValidate
      className={
        'group flex items-center bg-white p-4 rounded-full my-4 transition-all drop-shadow-sm border hover:border-sky-500/20 hover:ring-2 hover:ring-sky-500/20 max-w-[800px] w-full mx-auto ' +
        className
      }
    >
      <input
        type="text"
        autoFocus={autoFocus}
        id="searchInput"
        value={searchInputValue}
        onChange={handleSearchInputChange}
        onKeyDown={handleKeyDown}
        placeholder="Search by keyword, description, or simply ask a question..."
        className="flex-grow appearance-none bg-transparent border-none outline-none text-base px-2 placeholder-gray-400"
        autoComplete="off"
        ref={inputRef}
      />
      <div className="flex items-center">
        {searchInputValue && <Button icon={'x'} size={20} color={'dark'} className="mr-2" action={handleInputClear} />}
        <div className="w-px h-6 bg-gray-300 mx-2 hidden sm:block"></div>
        <div className="flex gap-2 px-2">
          {/* FIXME: uncomment when we have a target mode */}
          {/* <Tippy content={'Target Mode'} arrow={roundArrow} animation="shift-away" inertia={true}>
            <button type="button" onClick={toggleTargetMode}>
              <TargetIcon fill={isTargetMode ? undefined : '#6c6c6c'} />
            </button>
          </Tippy> */}
          <button type="submit" className=" " id="inputField">
            <SearchIcon />
          </button>
        </div>
      </div>
    </form>
  )
}

export default SearchForm
