const Style = {
  base: 'bg-white shadow-md rounded-md',
  item: 'border-b p-4 pl-5 border-gray-200 overflow-hidden',
  button: 'w-full text-left focus:outline-none flex justify-between items-center',
  title: 'font-medium text-sm text-brand-500',
  icon: 'text-brand-500 transition-transform duration-200',
  iconOpen: 'transform rotate-180',
  content: 'transition-all duration-300 ease-in-out',
  contentHidden: 'max-h-0 opacity-0 pointer-events-none',
  contentVisible: 'max-h-full opacity-100 pt-1 ',
}

export default Style
