import { Switch } from 'components/lib'
import { useState } from 'react'

import Tippy from '@tippyjs/react'
import Accordion from 'components/accordion'
import CheckboxGroup from 'components/form/checkboxGroup'
import { roundArrow } from 'tippy.js'
import 'tippy.js/animations/shift-away.css'
import 'tippy.js/dist/svg-arrow.css' // SVG arrow styles
import 'tippy.js/dist/tippy.css' // Core styles

export function ChatNamespaceSelector({ checkedNS, setCheckedNS, namespaceCheckItems }) {
  const [auto, setAuto] = useState(checkedNS['Auto'].checked)

  const checkboxItems = Object.keys(namespaceCheckItems).map((name) => ({
    title: name,
    content: <CheckboxGroup items={namespaceCheckItems[name]} />,
  }))

  return (
    <div id="namespaceSelector">
      <Tippy
        content="The AI automatically considers the most important sources (recommended)"
        animation="shift-away"
        arrow={roundArrow}
        inertia={true}
      >
        <span className="font-medium ml-2 select-none text-[0.8rem]">Auto</span>
      </Tippy>
      <Switch
        default={auto}
        name="Auto"
        required={true}
        onChange={(_, checked) => {
          const newCheckedNS = { ...checkedNS }
          newCheckedNS['Auto'].checked = checked
          setCheckedNS(newCheckedNS)
          setAuto(checked)
        }}
      />

      {!auto && (
        <Accordion
          className="absolute w-full left-0 border-t h-[calc(100%-4rem)] overflow-y-auto !rounded-t-none border-gray-200 mt-2"
          items={checkboxItems}
        />
      )}
    </div>
  )
}
